import React from "react"
import {
  FaLinkedin,
  FaGithubSquare,
  FaDev,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa"

const SocialLinks = ({ contacts }) => {
  return (
    <div className="social-links float-right mr-4">
      <a className="ml-4" href={contacts.linkedin}>
        <span title="LinkedIn">
          <FaLinkedin size={40} color={"#2867B2"} />
        </span>
      </a>
      <a className="ml-4" href={contacts.twitter}>
        <span title="Twitter">
          <FaTwitterSquare size={40} color={"#1DA1F2"} />
        </span>
      </a>
      <a className="ml-4" href={contacts.instagram}>
        <span title="Instagram">
          <FaInstagram size={40} color={"#C13584"} />
        </span>
      </a>
      <a className="ml-4" href={contacts.github}>
        <span title="GitHub">
          <FaGithubSquare size={40} color={"#999"} />
        </span>
      </a>
      <a className="ml-4" href={contacts.dev}>
        <span title="Dev">
          <FaDev size={40} color={"#14171A"} />
        </span>
      </a>
    </div>
  )
}

export default SocialLinks
